.abs-pos {
  position: absolute;
  z-index: 99999999;
}

.block {
  display: block;
}

.react-player-mute-button{
  z-index: 10000;
  position: fixed;
  right: 2px;
  top: 125px;
}

.react-player-mute-unmute-button{
  z-index: 10000;
  position: fixed;
  right: 2px;
  top: 120px;
  background: transparent;
  border: none;
  padding: 9px 8px 9px 10px;
}

.react-player-play-pause-button{
  z-index: 10000;
  position: fixed;
  right: 2px;
  top: 160px;
  background: transparent;
  border: none;
}

.story-img-skeleton-loader {
  width: 100%;
  height: 100vh;
  animation: pulse 1s infinite;
  display: block;
  position: fixed;
  top: 0;
}

.explore_loader_wrapper {
  border-right-color: grey;
}

.exploreDishNameTitle {
  margin: 10px 0px 0px 5px;
  font-size: 16px;
}

@keyframes pulse {
  0% {
    background-color: #181818;
  }

  50% {
    background-color: #2e2e2e;
  }

  100% {
    background-color: #181818;
  }
}

.image-container {
  position: relative !important;
}

.story-image-container {
  width: 100%;
  height: auto;
  display: block;
}

.gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  /* Adjust the height of the gradient as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
}

.top-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6%;
  /* Adjust the height of the gradient as needed */
  background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0 0 0 / 70%));
}
.ingrident-list-top-wrapper{
  height: 15px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000a1)
}

.swiper_Top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px 10px 18px;
}

.swiper_Top .swiper_Title {
  display: flex;
  align-items: center;
}

.swiper_Title_img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.swiper_Title_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.swiper_Top .swiper_Title p {
  margin-left: 0px;
  color: #fff;
}

.swiper-slide>div {
  background-color: #000;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100% !important;
  position: relative;
}

.swiper_top_menu {
  color: #fff;
  padding: 5px 0;
  display: flex;
  overflow: hidden;
}

.swiper_top_menu ul {
  display: flex;
  overflow: hidden;
  text-transform: capitalize;
  font-size: 16px;
}

.swiper_top_menu ul li {
  width: fit-content;
  min-width: fit-content;
  padding: 0 12px;
  text-align: left;
  min-width: 100%;
}

.swiper_top_menu ul li.active {
  text-decoration: underline;
}

.swiper_top_back_btn button img {
  margin-top: 8px;
}

.swiper_top_back_btn button {
  height: fit-content;
  line-height: initial;
  padding: 20px;
}

.swiper_top_back_btn {
  width: 10px;
  min-width: 10px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
}

.swiper_top_menu .dishItems {
  width: 100%;
}

.swipper_bottom {
  color: #fff;
  padding: 10px 10px 15px 20px;
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
}

.explore_swipper_bottom_first {
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 5px 25px;
  border-radius: 10px 10px 0px 0px;
  justify-content: space-between;
}
.ingrident-list-wrapper{
  padding: 5px 25px;
}
.explore_swiper_Top{
  background: #000000a1;
  border-radius: 0px 0px 10px 10px;
  padding: 20px 10px 10px 25px;
}
.addcart_btn button {
  height: 41px;
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 0 3px 0 16px;
  background: #f8edd6;
  position: absolute;
  right: 20px;
  z-index: 99999999999;
}
.ingrident-list-header-label{
  display: flex;
  column-gap: 12px;
  margin-bottom: 15px;

}
.ingrident-list-header-label span{
  text-transform: uppercase;
  color: #b8b5b5;
  opacity: 0.5;
}
.ingrident-list-header-label-active{
  color: #fff !important;
  opacity: 1 !important;
}
.addcart_btn button p {
  margin-right: 10px;
  color: #a26057;
}

.addcart_btn button span {
  color: #fff;
  background-color: #e9804e;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  display: inherit;
  border-radius: 50%;
  font-size: 40px;
  justify-content: center;
  line-height: 25px;
}

.items_price {
  margin-right: 10px;
}

.swipper_bottom_first .items_value .plus_taxes p {
  color: #949494;
  margin-top: 5px;
  margin-left: 9px;
}

.items_value .items_price h5 {
  color: #e6e6e6;
  font-size: 18px;
}

.items_value .items_price h6 {
  color: #c5c5c5;
  font-size: 14px;
}

.swipper_bottom>h6 {
  color: #a85c39;
  font-size: 14px;
  margin-top: 8px;
}

/* .swipper_main_img {
  height: 257px;
  min-height: 257px;
  max-height: 257px;
} */
.swipper_main_img img {
  width: 100%;
  height: 100%;
}

.plus_taxes {
  display: flex;
  margin: 5px 0px 1px 0px;
}

@supports (-webkit-touch-callout: none) {
  /* .single_stories_wrapper>div>div:nth-child(2) {
    height: 65vh !important;
  } */

  .single_stories_wrapper>div {
    justify-content: center !important;
  }

  .single_stories_wrapper>div>div:nth-child(1) {
    top: 0 !important;
  }

  #dishclick.AppContainer_left__8clJu {
    min-height: 85vh !important;
  }

  .swiper-container {
    /* height: initial !important; */
    height: 100vh !important;
    background: #111 !important;
  }

  .sess {
    height: 100%;
    background: #111 !important;
  }

  .swipper_bottom {
    bottom: 70px;
  }
}

.chef-info-name-wrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.chef-time-wrapper {
  display: flex;
  align-items: center;
  column-gap: 2px;
}

.explore-content-description {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 300;
}

.chef-online {
  background: #00b300;
}

.chef-away {
  background: #ff0000;
}

.chef-non-serviciable {
  background: #808080;
}

.explore-chef-status-indicator {
  display: flex !important;
  gap: 0.4rem;
  width: auto;
  margin-top: 0.5rem;
}

.explore-chef-status-indicator>div {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 100rem;
}

.explore-chef-status-indicator p {
  flex: 2;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: #808080;
  flex-wrap: wrap;
  padding-left: 2px;
}

.explore-addcart_btn button {
  font-size: 14px;
  height: 40px;
  position: static;
  background: #fff;
  padding: 0 3px 0 8px;
}

.explore-addcart_btn {
  /* margin-top: 3px; */
  z-index: 99999999999;
}

.explore-addcart_btn button span {
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
  font-size: 28px;
  line-height: 17px;
  background-color: #fff;
  color: #a26057;
}

.explore-chef-label {
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  z-index: 99999999999;
  margin-top: 3px;
}
.explore-story-menu-button{
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 4px;
  font-weight: 300;
  font-size: 13px;
  z-index: 99999999999;
}
.explore-story-menu-pause-button{
  z-index: 99999999999;
  background: transparent;
  border: 0px;
  margin-left: auto;
}
.explore-story-menu-pause-button:focus{
  background: transparent;
  border: 0px;
}
.explore-chef-timing-label {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}

.explore_swiper_Title_img {
  z-index: 99999999999;
  width: 35px;
  height: 35px;
}

.explore-offer-label-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.explore-swipper_bottom {
  padding: 0px;
  background: #000000a1;
}
.hide-story-image{
  position: absolute;
  height: 10px;
  width: 10px;
  opacity: 0;
}
.demoHeaderContent{
  padding: 20px 0 3px 0px;
  display: flex;
  justify-content: center;
}

.exploreChefScreen {
  background: transparent;
  border-radius: 10px;
  padding-bottom: 10px;
}

.exploreChefScreen.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.exploreChefScreen.show {
  /* position: relative; */
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease-in;
}

.explore-video-player .video-react-fluid .video-react-video{
  width: 100%;
}
.explore-video-player .video-react-fluid{
  padding-top: 0px !important;
}
.explore-video-player .video-react-big-play-button{
  display: none !important;
}
.explore-video-player .video-react-control-bar{
  display: none !important;
}