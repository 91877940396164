.install-modal-wrapper {
    margin: 20px 30px 30px 30px;
}

.install-modal-emogy {
    margin: 0 auto;
    width: min-content;
}

.install-app-label {
    font-family: "Nunito";
    font-size: 22px;
    font-weight: 700;
    line-height: 33px;
    padding-top: 15px;
    color: #222D30;
    margin: 0 auto;
    width: fit-content;
}

.install-app-description {
    font-family: "Nunito";
    font-size: 19px;
    font-weight: 600;
    line-height: 27px;
    padding-top: 10px;
    padding-bottom: 28px;
    text-align: center;
    color: #222D30;
    width: 84%;
    margin: 0 auto;
}

.download-now-button-modal {
    background: #E03C31;
    color: #fff;
    border: 1px solid #E03C31;
    border-radius: 50px;
    width: 100%;
    padding: 16px 0px;
    font-family: Nunito;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
}
.download-now-button-modal:focus {
    background: #E03C31;
}
.install-modal-popup .react-modal-sheet-container{
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}
.vegchoice-modal-sheet .react-modal-sheet-container{
    transform: none !important;
}
.closeIconInstallModal{
    position: relative;
    bottom: 40px;
    margin: 0 auto;
}
.enter-location-manually-link{
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: #007bff; 
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
}
.enter-location-manually-link:focus{
    background: none;
}