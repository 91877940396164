.query-button-container {
    padding: 18px 16px;
    border-bottom: 1px solid #e9e9eb;
    font-size: 14px;
    font-weight: 300;
    color: #282c3f;
}

.message-list-wrapper {
    overflow: hidden scroll;
    flex: 1 1 0%;
    display: flex;
    padding: 12px 12px 24px;
    flex-direction: column;
    max-height: calc(100vh - 200px);
}

.single-message-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.single-message-chatbox {
    margin-top: 12px;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.single-message-chatbox-reply {
    margin-top: 12px;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.single-message-chatbox-radius {
    padding: 12px 12px 12px;
    position: relative;
    word-break: break-word;
    background: rgb(81, 52, 182);
    margin-left: 20px;
    max-width: 350px;
    border-radius: 20px 20px 8px;
    color: #ffffffeb;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
}

.single-message-chatbox-radius-reply {
    padding: 12px 12px 8px;
    position: relative;
    word-break: break-word;
    background: rgb(240, 240, 245);
    border-radius: 20px 20px 20px 8px;
    margin-right: 20px;
    max-width: 350px;
    color: rgba(2, 6, 12, 0.92);
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
}

.single-message-chatbox-radius-reply svg{
    margin-right: 7px;
}

.chatInputArea {
    width: calc(100% - 82px) !important;
    margin-bottom: 0px !important;
}

.chatInputAreaWrapper {
    position: fixed;
    bottom: 60px;
    background-color: #fff;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    column-gap: 6px;
    margin: 0px 6px 6px 6px;
}
.chatInputAreaForm{
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.message-list-wrapper-container {
    position: relative;
}

.supportChatButton:focus {
    background-color: #1677ff;
}
.chatInputAreaWrapper .ant-form-item{
    margin-bottom: 0px;
}