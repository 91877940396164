.slider-custom-carousel .carousel {
  height: auto !important;
  -webkit-perspective: initial !important;
  perspective: initial !important;
  -webkit-transform-style: initial !important;
  transform-style: initial !important;
  -webkit-transform-origin: initial !important;
  transform-origin: initial !important;
  overflow: initial !important;
}

.slider-custom-carousel .carousel .slider {
  height: auto !important;
}

.slider-custom-carousel .carousel .slider-wrapper {
  /* border-radius: 10px; */
}

.slider-custom-carousel {
  /* margin-bottom: 12px; */
}

.carouselOverlayCuisineName {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(239, 239, 239, 0.8) 47.5%, rgba(191, 191, 191, 0.8) 100%);
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  line-height: 13px;
  font-size: 10px;
}

.carouselOverlayContentWrapper {
  position: relative;
}

.centerContentDiv{
  position: absolute;
  height: 100%;
  margin: auto 0;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carouselOverlayContent {
  position: absolute;
  top: 0;
  margin-top: 10px;
  padding: 0;
  text-align: start;
  width: auto;
  z-index: 1;
  left: 10px;
  display: flex;
  column-gap: 5px;
}

.carouselTopBackbtn{
  position: absolute;
  top: 20px;
  bottom: auto;
  left: 20px;
}

.chefListScreenContainer {
  position: relative;
}

.chefCardWrapper {
  background: #ffffff;
  border-radius: 10px;
}

.chefCardWrapper.hide {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.chefCardWrapper.show {
  position: relative;
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s ease-in;
}

.chefListScreenComponent {
  transition: transform 0.3s ease-out;
  background: #f7f7f7;
  margin-bottom: 70px;
}

.chefDetailScreenComponent {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  animation: slideIn 0.5s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}


.chefListScreenContainerWrapper {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.chefListScreenContainerWrapper.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

.chefListScreenContainerWrapper .chefListScreenContainer {
  width: 100%;
  min-width: 350px;
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

.chefListScreenContainerWrapper.open .chefListScreenContainer {
  transform: translateX(0);
}

.carousel-react-player video {
  object-fit: cover;
}
.carousel-react-player{
  width: 100%;
}

.chefDetailInfoWrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 8px 20px;
}

.chefDetailInfoWrapperInner {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000a1);
  padding: 10px 20px 12px 20px;
}

.carouselOverlayContentMain {
  top: auto;
  bottom: 52px;
  left: 20px;

}

.chefInfoWrapperDescription {
  padding-top: 15px;
  color: #575A62;
}

.chefInfoWrapperDescription span {
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 12px;
}

.successRateLabel {
  background: #1EB36B;
  border-radius: 4px;
  color: #ffffff;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  column-gap: 2px;
  width: fit-content;
  font-size: 12px;
}

.sucessDishesCountLabel {
  color: #808285;
  font-size: 10px;
  font-weight: 600;
  margin-top: 2px;
}

.chefLeftInfoDesc {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.chefCardDiscountLabelWrapper {
  margin: 5px 20px 0px 20px;
  background: #EAFDF4;
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 12px;
}

.chefLikeSvgIcon {
  background: none;
  border: 0px;
  position: absolute;
  top: 7px;
  right: 5px;
}
.chefPhotoClickDiv{
  position: absolute;
  bottom: 0px;
  height: calc(100% - 45px);
  width: 100%;
}

.chefLikeSvgIcon:focus {
  background: none;
}

.chefDistanceLabel::before{
  content: "• ";
  color: #0000008c;
}