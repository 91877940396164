.portionSelectorBox {
    margin: 20px 0px 20px 0px;
    background-color: #8080801a;
    border-radius: 7px;
}

.portionCheckSelect {
    position: relative;
    opacity: 1;
}

.portionCheckSelectLabel {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.portionViewSelectorBox {
    padding: 15px 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dishInfoPortionSelector {
    padding: 50px 0px 0px 0px;
    margin: 0px -15px 0px -15px;
    border-radius: 50px 50px 0px 0px;
    display: flex;
    column-gap: 13px;
    margin: 0px;
    width: 100%;
}

.customizeDescContainer {
    padding: 22px 0px 20px 0px;
}

.dishInfoPortionWrapper {
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-left: 30px;
}

.dishInfoPortionBoxWrapper {
    margin-bottom: 20px;
}

.customizeLabel {
    padding-bottom: 22px;
    width: inherit;
    border-bottom: 1px dotted grey;
}

.optionalText {
    font-size: 12px;
    color: #808285;
}

.vegnonVegLogo span {
    font-size: 7px;
    line-height: 8px;
    font-weight: 800;
    text-align: center;
}

.vegnonVegLogo .food-list-item.veg figure span {
    color: #6a9902;
}

.vegnonVegLogo .food-list-item.egg figure span {
    color: #eaa215;
}

.vegnonVegLogo .food-list-item.non-veg figure span {
    color: #be360d;
}

.vegnonvegFigure {
    display: flex;
    flex-direction: column;
}

.dishInfoPortionSelectorHeader {
    text-transform: capitalize;
    font-size: 16px;
}

.portionCheckSelect .form-check-input {
    opacity: 1;
    position: relative;
    top: 2px;
}

.dishInfoPortionSelectorQuantityHeader {
    padding: 15px 17px;
    border-bottom: 1px solid #80808080;
}

.react-modal-sheet-container {
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
}

.dishPortioncheckbox .ant-checkbox-input {
    position: absolute;
}

.dishPortioncheckbox .ant-checkbox+span {
    font-size: 13px !important;
}

.dishPortioncheckbox .ant-checkbox-inner {
    display: block !important;
    height: 16px !important;
    position: relative !important;
    padding-left: 0px !important;
}

.dishPortioncheckbox .ant-checkbox-inner::before {
    content: none !important;
}

.dishPortioncheckbox .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: #94C528 !important;
}

.dishInfoVegLogoWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    margin-top: 5px;
}
.customizeBoxLabel{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.customizeDishInfoLabel{
    display: flex;
    flex-direction: row;
    column-gap: 6px;
}

.dishInfoPortionBottomWrapper {
    display: flex;
    column-gap: 15px;
    margin-top: 16px;
}

.dishPortioncheckbox {
    flex-direction: column;
    row-gap: 6px;
}

.extraPriceLabel {
    color: #808285;
    font-size: 12px;
    line-height: 15px;
}

.dishPortioncheckbox .ant-checkbox-inner::after {
    opacity: 1 !important;
    transform: rotate(45deg) scale(1) translate(-50%, -50%) !important;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s !important;
    box-sizing: border-box !important;
    position: absolute !important;
    top: 50% !important;
    inset-inline-start: 21.5% !important;
    display: table !important;
    width: 5.7142857142857135px !important;
    height: 9.142857142857142px !important;
    border: 2px solid #fff !important;
    border-top: 0 !important;
    border-inline-start: 0 !important;
    content: "" !important;
    margin-top: 0 !important;
}

.scheduleTotalTitle {
    color: #7D9A6F;
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
}

.scheduleTotalTitleSum {
    color: #000000;
    font-size: 12px;
    font-weight: 800;
    line-height: 17px;
    margin-left: -5px;
}

.scheduleTotalTitleSumWrapper {
    display: flex;
    flex-direction: column;
}

.customizeTextBoxWrapper {
    background: #FFFFFF;
    padding: 13px 0px 13px 33px;
}

.customizeTextBoxWrapper span {
    color: #808285;
    font-size: 12px;
    font-weight: 400;
}

.customizeBottomSlideBarWrapper {
    box-shadow: rgba(0, 0, 0, 0.85) 0px 5px 15px;
}

.customizeBottomSlideBar {
    display: flex;
}
.closeButtonWrapper{
    display: flex;
    justify-content: center;
    position: relative;
}
.closeButtonBox{
    height: 40px;
    width: 40px;
    background: #EAA315;
    border-radius: 50%;
    position: absolute;
    top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input-group-customize-add-items{
    background-color: #f8edd5;
    height: 20px;
    border-radius: 4.5rem;
    padding: 0;
    width: 75px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    flex-wrap: wrap;
    align-items: stretch;
}
.btn-customizebtn-minus-item{
    left: 2px;
    display: inline-block;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    text-decoration: none;
    color: #fff;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    box-shadow: none;
    border: 0px;
}
.btn-customizebtn-minus-item img{
    display: block;
    height: 18px;
    width: 18px;
    object-fit: contain;
    object-position: center;
    background-color: #e8814d;
    border-radius: 100%;
    padding: 4px;
    box-shadow: 2px 2px 5px -2px #a6abbd;
}
.btn-customizebtnbtn-add-item{
    right: 2px;
    display: inline-block;
    height: 18px;
    width: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    font-size: 14px;
    box-shadow: none;
    text-decoration: none;
    color: #fff;
    text-align: center;
    letter-spacing: .5px;
    transition: background-color .2s ease-out;
    cursor: pointer;
    background-color: transparent;
    font-size: 14px;
    box-shadow: none;
    border: 0px;
}
.btn-customizebtnbtn-add-item img{
    display: block;
    height: 18px;
    width: 18px;
    object-fit: contain;
    object-position: center;
    background-color: #e8814d;
    border-radius: 100%;
    padding: 4px;
    box-shadow: 2px 2px 5px -2px #a6abbd;
}
.customizebtn-item-center-label{
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4.5rem;
    color: #670000;
    box-sizing: border-box;
}