.scheduleOrderButton{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    font-size: 12px;
    border-radius: 30px;
    border: 0px;
    background-color: #eee;
    color: rgb(0, 0, 0);
}
.scheduleDateTimePickerSelect{
    width: 100%;
}
.MuiPopover-root{
    z-index: 9999 !important;
}
.scheduleOrderButton:focus{
    background-color: #eee;
}
.ScheduleOrderButtonClock{
    display: flex;
    margin-right: 2px;
}
.ScheduleOrderButtonDropDown{
    display: flex;
}
.scheduleOrderModal{
    bottom: 85px;
    border-radius: 11px;
    z-index: 9999;
    width: 85%;
}
.scheduleDeliveryDetail{
    margin-bottom: 55px;
}
.scheduleTimeWrapperBoxCard{
    padding: 24px;
}
.scheduleTimeWrapperBoxCard .react-datepicker__day-name{
    width: 2.5rem !important;
    line-height: 2.5rem !important;
}
.react-datepicker__current-month{
    font-weight: 400 !important;
    padding-bottom: 10px;
}
.react-datepicker__header {
    padding-top: 15px !important;
}
.react-datepicker__navigation{
    top: 15px !important;
}
.react-datepicker__navigation:focus{
    background: transparent !important;
}
.scheduleTimeDatePlaceholder{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    padding: 14px 14px 14px 16px;
    color: rgb(0, 0, 0);
    background-color: rgb(238, 238, 238);
    border-color: rgb(238, 238, 238);
    display: flex;
    align-items: center;
    height: 48px;
}
.scheduleTimeDateCalender{
    margin-right: 12px;
}
.scheduleTimeDateDownArrow{
    margin-left: auto;
}
.scheduleDeliveryConfirmBtn{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    padding: 14px 16px;
    color: rgb(255, 255, 255);
    background-color: #7d9a6f;
    width: 100%;
    border: 0px;
}
.addItemScheduleDeliveryConfirmBtn:disabled{
    opacity: 0.7;
}
.scheduleDateTimePicker{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 8px;
    padding: 14px 14px 14px 16px;
    color: rgb(0, 0, 0);
    background-color: rgb(238, 238, 238);
    border-color: rgb(238, 238, 238);
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
}
.react-datepicker__day--selected{
    background-color: #7d9a6f !important;
}
.scheduleDateTimePicker:before{
    content: none !important;
}
.react-datepicker__day--keyboard-selected{
    background-color: #7d9a6f6e !important;
}
.react-datepicker__month{
    margin: 1rem !important;
}
.react-datepicker__portal .react-datepicker__day{
    width: 2.5rem !important;
    line-height: 2.5rem !important;
}
.react-datepicker{
    font-size: 0.9rem !important;
}
.scheduleDateTimePicker .MuiInput-input{
    padding: 0px !important;
    border: 0 !important;
    color: rgb(0, 0, 0) !important;
    margin-bottom: 0 !important;
    margin-left: 30px !important;
}
.scheduleDateTimePickerWrapper{
    position: relative;
}
.ClockSVGComponent{
    position: absolute;
    left: 17px;
    z-index: 1;
    top: 16px;
}
.DropDownArrowSVGComponent{
    position: absolute;
    right: 17px;
    z-index: 1;
    top: 16px;

}

.MuiDialog-root{
    z-index: 10000 !important;
}
.scheduleDateTimePickerSelect .MuiSelect-select{
    padding-left: 40px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
}
.scheduleDateTimePickerSelect .MuiFilledInput-root{
    background-color: rgb(238, 238, 238);
    border-color: rgb(238, 238, 238);
    border-radius: 8px;
}
.MuiMenu-paper{
    max-height: 300px !important;
}
