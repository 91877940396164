.order-status-screen-wrapper-container {
  position: relative;
}

.order-status-screen-wrapper {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.orderSummary_wrapper {
  margin-top: 20px;
}
.cooking-instruction-icon-wrapper{
  display: grid;
  column-gap: 10px;
  grid-template-columns: 38px auto;
}
.cooking-instruction-text-content{
  font-size: 13px;
  line-height: 13px;
}
.cooking-instruction-icon{
  width: 38px;
  height: 38px;
  max-width: 38px;
  max-height: 38px;
  border: 1px solid grey;
  border-radius: 50%; 
  display: flex;
  align-items: center;
  justify-content: center;
}
.cooking-instruction-text{
  color: #808285;
}
.orderSummary_top {
  text-align: center;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderSummary_icon {
  margin-right: 1rem;
  display: flex;
}

.billing-title-label {
  margin: 0px 10px;
}

.price-box-bottom-line {
  border-top: 1px solid #ccc;
  padding: 15px 5px 5px 5px;
  margin: 0px;
}

.price-title-label {
  margin: 6px 0px 6px 0px;
}

.order-info {
  margin-top: 30px;
  padding: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-box-wrapper {
  margin: 10px;
}

.order-info_span {
  font-weight: lighter;
  margin-left: 5px;
}

.order-info_right {
  width: 15%;
}

.order-info_right>span,
.order-info_left>span {
  color: #670000;
}

.delivery-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2px;
}

.status_badge {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  /* background-color: #7d9a6f; */
  color: green;
}

.delivery-address {
  margin-top: 2rem;
}

.delivery-address_top {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
}

.delivery-address_span {
  font-weight: bold;
  color: #670000;
}

.delivery-address_tag {
  margin-left: 10px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 3px 5px;
  background: #b5aeb2c4;
  border-radius: 6px;
  ;
}

.delivery-address_content {
  width: 80%;
  padding-left: 10px;
  margin-top: 0.5rem;
}

.order-details {
  width: 90%;
  margin: 15px;
  font-weight: bold;
  margin-top: 1rem;
  color: #670000;
  font-size: 14px;
  margin-left: 1.5rem;
}

.ordered-dishes_container {
  margin-top: 1rem;
  padding: 0px 15px 0px 15px;
}

.call-button {
  justify-content: center;
  width: fit-content;
  padding: 5px;
  border-radius: 8px;
  color: #fff;
  margin-top: 0 !important;
}

.call-button .order-info_time a {
  color: #670000e6;
  text-decoration: underline;
}

.call-button .order-info_icon svg {
  height: 14px;
  width: 14px;
}

.call-button .order-info_icon svg path {
  fill: #670000e6;
}

.order-status-kitchen-name {
  padding: 15px 15px 3px 15px;
  display: flex;
  justify-content: space-between;
}

.order-status-topbar {
  text-align: center;
  background: #7d9a6f;
  color: #fff;
}

.order-status-main-header {
  font-size: 25px;
}
.order-status-main-header-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 15px;
}

.order-status-estimate-time-wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  padding-bottom: 15px;
}

.order-status-estimate-time-label {
  background: #b2c1ab61;
  border-radius: 7px;
  padding: 5px 10px;
  width: fit-content;
}

.order-status-refresh-btn {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  border-radius: 7px;
  background: #b2c1ab61;
}

.order-status-refresh-btn:focus {
  background-color: #b2c1ab61;
}

.order-status-refresh-icon {
  display: flex;

}

.order-status-refresh-icon-loading {
  animation: rotation 1s linear infinite;
}

.order-status-kitchen-call {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff6b;
  border-radius: 50%;
}

.delivery-partner-logo-wrapper {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 6px;
}

.delivery-partner-name-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 19px;
}

.delivery-partner-name {
  font-weight: 700;
}

.delivery-partner-name-label {
  font-weight: 300;
  color: grey;
}

.delivery-partner-info-wrapper {
  margin: 15px 10px 10px 10px;
  background: #59a5d112;
  border-radius: 11px;
  padding: 12px 5px;
}

.map-tracking-component {}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}