.chefInfoTabs .ant-tabs-nav {
  padding: 0px 15px 0px 15px;
}

.chef-individual-screen-wrapper {
  max-width: 550px;
  width: 100%;
}

.chef-individual-screen {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
}

.chef-individual-screen.open {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease;
}

.chef-individual-screen .chefImageWrapper {
  width: 100%;
  min-width: 350px;
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

.chef-individual-screen.open .chefImageWrapper {
  transform: translateX(0);
  height: 100vh;
}

.ant-tabs-tabpane {
  transition: transform 0.3s ease;
  transform: translateX(100%);
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  transform: translateX(0);
}

.ant-tabs-tabpane-hidden {
  display: table-column !important;
}

.ant-tabs-tabpane:not(.ant-tabs-tabpane-active) {
  display: table-column !important;
}

.chefInfoTabs .ant-tabs-nav-wrap {
  background: #EAEAEA;
  border-radius: 8px;
  padding: 8px 12px;
}

.chefInfoTabs .ant-tabs-nav-wrap .ant-tabs-tab-btn {
  font-size: 11px;
}

.chefInfoTabs .ant-tabs-nav-wrap .ant-tabs-tab {
  padding: 5px 25px;
  margin: 0px;
}

.chefInfoTabs .ant-tabs-nav-wrap .ant-tabs-tab-active {
  background: #FFFFFF;
  border-radius: 8px;
}

.chefInfoTabs .ant-tabs-nav-wrap .ant-tabs-tab-btn {
  color: #000000e0 !important;
}

.chefInfoTabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%;
  column-gap: 15px;
}

.chefInfoTabs .ant-tabs-nav-wrap .ant-tabs-ink-bar-animated {
  display: none;
}

.menuCategoriesWrapper {
  margin-top: -23px;
}

.menuCategoriesWrapper .collapsible {
  border-top: 0px !important;
}

.aboutChefInfoWrapperDescription {
  padding: 0px 21px 14px 21px;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}